const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
};

const rgbToHex = (r: number, g: number, b: number) => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const interpolate = (start: number, end: number, steps: number, step: number) => {
  return Math.round(start + ((end - start) / steps) * step);
};

export const generateColorRamp = (startColor: string, endColor: string, steps: number) => {
  const start = hexToRgb(startColor);
  const end = hexToRgb(endColor);
  const ramp = [];

  for (let i = 0; i <= steps; i++) {
    const r = interpolate(start.r, end.r, steps, i);
    const g = interpolate(start.g, end.g, steps, i);
    const b = interpolate(start.b, end.b, steps, i);
    ramp.push(rgbToHex(r, g, b));
  }

  return ramp;
};
