import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import { Code, CodeResponse, CodesResponse, Medium, MediumsResponse, Source, SourcesResponse } from "@interfaces/codes";
import { ResponseBase } from "@interfaces/response";

const codesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCodes: build.query<Array<Code>, string>({
      query: (codeToSearch) => ({
        url: `codigos/codes`,
        params: {
          start_with: `code:${codeToSearch}`,
          size: 10000,
        },
      }),
      transformResponse: (response: CodesResponse) => {
        return response.data.data;
      },
    }),
    getCodeById: build.query<Code, UUID>({
      query: (codeId) => ({
        url: `codigos/codes/${codeId}`,
      }),
      transformResponse: (response: CodeResponse) => {
        return response.data;
      },
    }),
    postCode: build.mutation<any, any>({
      query: (body) => ({
        url: `codigos/codes`,
        method: "POST",
        body: body,
      }),
    }),
    getSources: build.query<Array<Source>, null>({
      query: () => ({
        url: `codigos/sources`,
      }),
      transformResponse: (response: SourcesResponse) => {
        return response.data.data;
      },
    }),
    getMediums: build.query<Array<Medium>, any>({
      query: (params) => ({
        url: `codigos/mediums`,
        params: params,
      }),
      transformResponse: (response: MediumsResponse) => {
        return response.data.data;
      },
    }),
    getMedium: build.query<any, UUID>({
      query: (mediumId) => ({
        url: `codigos/mediums/${mediumId}`,
      }),
      transformResponse: (response: ResponseBase) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetCodesQuery,
  useLazyGetCodesQuery,
  useLazyGetCodeByIdQuery,
  useLazyGetSourcesQuery,
  useLazyGetMediumsQuery,
  useLazyGetMediumQuery,
  usePostCodeMutation,
} = codesApi;
