import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import DownloadWaitIcon from "@assets/download_wait_icon.svg";
import DownloadWarningIcon from "@assets/download_warning_icon.svg";

interface DetalleAbortivoProps {
  props: any;
}

export const DetalleAbortivo = (props: DetalleAbortivoProps) => {
  const translateText: any = {
    sales_volume: "Ventas",
    government_sales: "Ventas Gubernamentales",
    income_variation_last_3_months: "Variación de Ingresos",
    payment_capacity: "Capacidad de pago",
    seniority: "Antigüedad",
    zone: "Zona",
    activity: "Actividad",
    shareholder_or_pfae_age: "shareholder_or_pfae_age",
    declaration: "Declaration",
    credit_bureau_credit_history: "Histórico Créditos Buró",
    percentage_mops_1: "Porcentaje MOPS 1",
    mops_matrix: "Matriz MOPS",
    debt_restructuring_bankruptcies_dations: "Quitas, Quebrantos, Daciones",
    fiscal_credits: "Créditos Fiscales",
    overdue_balance: "Saldo Vencido",
  };
  return (
    <Box m={2}>
      <Table>
        <TableBody>
          {Object.keys(props.props)
            .filter((k) => k !== "show_menu")
            .map((k: any, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell padding="normal" sx={{ borderBottom: "1px solid #A3D4E8", paddingRight: 5 }}>
                    <Typography variant="body3" fontWeight={500} color="main3">
                      {translateText[k]}
                    </Typography>
                  </TableCell>
                  <TableCell padding="none" sx={{ borderBottom: "1px solid #A3D4E8" }} align="right">
                    {props.props[k] === true && (
                      <img src={DownloadWarningIcon} style={{ width: 18, height: 18, marginLeft: 2 }} />
                    )}
                    {props.props[k] === false && <CheckIcon sx={{ color: "#528CD6" }} />}
                    {props.props[k] === null && (
                      <img src={DownloadWaitIcon} style={{ width: 18, height: 18, marginLeft: 2 }} />
                    )}
                  </TableCell>
                  <TableCell padding="normal" sx={{ borderBottom: "1px solid #A3D4E8" }} align="right">
                    <Typography
                      variant="body3"
                      color={props.props[k] === true ? "main4" : props.props[k] === null ? "secondary4" : "main2"}
                      fontWeight={500}
                    >
                      {props.props[k] === true ? "No Cumple" : props.props[k] === null ? "Procesando" : "Cumple"}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Box>
  );
};
