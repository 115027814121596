import { Permission } from "@interfaces/users";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

interface HeimdallProps {
  children: React.ReactNode;
  scopes: Array<string>;
}

const hasPermission = (permissions: Array<Permission>, scopes: Array<string>) => {
  return scopes.some((scope) => permissions.some((permission) => permission.module.code === scope));
};

export const Heimdall = (props: HeimdallProps) => {
  const role = useSelector((state: RootState) => state.app.session.user);
  const permissions = useSelector((state: RootState) => state.app.session.permissions);

  const permissionGranted = role?.role?.code === "RADM2" ? true : hasPermission(permissions, props.scopes);

  if (!permissionGranted) return <></>;

  return <>{props.children}</>;
};
