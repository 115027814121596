import { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  Stack,
  Typography,
  Checkbox,
  TextField,
  Skeleton,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { DocumentInfo } from "@interfaces/documents";
import { setDialogConfig } from "@store/slices/componentsSlice";
import { useGetReasonRejectionDocsQuery } from "@services/api/catalogs";
import { usePatchDocumentMutation, usePostDocumentReasonRejectMutation } from "@services/api/documents";

export interface RechazarDocumentoProps {
  document: DocumentInfo | undefined;
  isProcessing: boolean;
  onBackClick: () => void;
  onConfirmClick: () => void;
}

export const RechazarDocumento = (props: RechazarDocumentoProps) => {
  const dispatch = useDispatch();

  const [isProcessing, setIsProcessing] = useState<boolean>(props.isProcessing);
  const userSession = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const reasonsRejection = useGetReasonRejectionDocsQuery();

  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerPostDocumentReasonReject] = usePostDocumentReasonRejectMutation();

  const initialValues = {
    option: "",
    other: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      option: yup.string().required(),
      other: yup.string().when("option", {
        is: "CRRDO",
        then: (schema) => schema.required("Este campo es requirido par la opción 'Otro'."),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: async (values) => {
      try {
        setIsProcessing(true);
        await triggerPatchDocument({
          applicationId: applicationSelected.id,
          documentId: props.document?.id ?? "0-0-0-0-0",
          body: {
            validated: false,
            user_name:
              userSession.name?.concat(" ", userSession?.last_name ?? "", " ", userSession?.last_name_2 ?? "").trim() ??
              "",
          },
        }).unwrap();

        const reasonSelected = reasonsRejection.currentData?.find((item) => item.code === values.option);
        await triggerPostDocumentReasonReject({
          documentId: props.document?.id ?? "0-0-0-0-0-0",
          body: {
            rejection_reasons: [
              {
                reason_rejection_document_id: reasonSelected?.id,
                other_reason: values.other,
              },
            ],
          },
        }).unwrap();

        props.onConfirmClick();
      } catch (error) {
        console.log("Error al Rechazar documento: %s", error);
      } finally {
        setIsProcessing(false);
      }
    },
  });

  const handleOnBackClicked = () => {
    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
    props.onBackClick();
  };

  return (
    <Box margin={4}>
      <Stack textAlign={"center"} spacing={2}>
        <Typography variant="body1" fontWeight={600} color={"#002652"}>
          Selecciona el motivo para{" "}
          <Typography component={"span"} variant="body1" fontWeight={600} color={"#F2704F"}>
            rechazar el documento
          </Typography>
          :
        </Typography>
        <Typography variant="h4" fontWeight={600}>
          {props.document?.category_name}
        </Typography>
        <Typography variant="h4" color={"#528CD6"}>
          ID SOLICITUD:{" "}
          <Typography component={"span"} variant="h4" fontWeight={600}>
            {applicationSelected.solicitudId}
          </Typography>{" "}
        </Typography>
        <form onSubmit={formik.handleSubmit} noValidate>
          <FormGroup>
            {reasonsRejection.isSuccess ? (
              reasonsRejection.data.map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      name={"option"}
                      checked={formik.values.option === item.code}
                      onChange={() => formik.setFieldValue("option", item.code)}
                      value={item.code}
                    />
                  }
                  label={item.name}
                />
              ))
            ) : (
              <>
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
              </>
            )}
          </FormGroup>
          {formik.values.option === "CRRDO" && (
            <TextField
              name="other"
              fullWidth
              required
              label={"Escribe el motivo"}
              autoComplete="off"
              value={formik.values.other}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.other && Boolean(formik.errors.other)}
              helperText={formik.touched.other && formik.errors.other}
            />
          )}

          <Grid container flexDirection={"row"} justifyContent={"space-around"} alignItems={"center"} marginTop={10}>
            <Grid>
              <Button variant={"blue_outlined"} onClick={handleOnBackClicked} disabled={isProcessing}>
                Regresar
              </Button>
            </Grid>
            <Grid>
              <Button
                variant={"contained"}
                type="submit"
                loading={isProcessing}
                disabled={reasonsRejection.isLoading || isProcessing}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Box>
  );
};
