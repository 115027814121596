import { lazy, Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { RootState } from "@store/store";
import { useSelector } from "react-redux";

import { MainLayout } from "./layouts/MainLayout";
import { AdminLayout } from "./layouts/AdminLayout";
import { SolicitudLayout } from "./layouts/SolicitudLayout";

const Login = lazy(() => import("@pages/Login"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const Solicitud = lazy(() => import("@pages/Solicitud/Solicitud"));
const RecuperarCuenta = lazy(() => import("@pages/RecuperarCuenta"));
const Adminitracion = lazy(() => import("@pages/admin/Administracion"));
const CambiarContrasenia = lazy(() => import("@pages/CambiarContrasenia"));

import "./App.css";

const SuspenseLayout = () => {
  return (
    <Suspense fallback={<></>}>
      <Outlet />
    </Suspense>
  );
};

function App() {
  const user = useSelector((state: RootState) => state.app.session.user);

  return (
    <Routes>
      <Route element={<SuspenseLayout />}>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="solicitud/:id" element={<SolicitudLayout />}>
            <Route index element={<Solicitud />} />
          </Route>
          {user.role?.code === "RADM2" && (
            <Route path="admin" element={<AdminLayout />}>
              <Route index element={<Adminitracion />} />
            </Route>
          )}
          <Route path="cambiar_contrasenia" element={<CambiarContrasenia />} />
          <Route path="recuperar_cuenta" element={<RecuperarCuenta />} />
        </Route>
        <Route path="*" element={<Navigate to={"/login"} />} />
      </Route>
    </Routes>
  );
}

export default App;
