import { UUID } from "crypto";
import { baseApi } from "./baseApi";

import { Module, ModulesResponse, Role, RolesResponse } from "@interfaces/roles";

const rolesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<Array<Role>, any>({
      query: () => ({
        url: "roles",
      }),
      transformResponse: (response: RolesResponse) => {
        return response.data.data;
      },
    }),
    postRole: build.mutation<Role, any>({
      query: (body) => ({
        url: "roles",
        method: "POST",
        body: body,
      }),
    }),
    patchRole: build.mutation<Role, { roleId: UUID; body: any }>({
      query: ({ roleId, body }) => ({
        url: `roles/${roleId}`,
        method: "PATCH",
        body: body,
      }),
    }),
    deleteRole: build.mutation<Role, UUID>({
      query: (roleId) => ({
        url: `roles/${roleId}`,
        method: "DELETE",
      }),
    }),
    getModulesByCode: build.query<Module | undefined, string>({
      query: (code) => ({
        url: `modules`,
        params: {
          q: `code:${code}`,
        },
      }),
      transformResponse: (response: ModulesResponse) => {
        return response.data.data.at(0);
      },
    }),
  }),
});

export const {
  useLazyGetRolesQuery,
  useGetRolesQuery,
  usePostRoleMutation,
  usePatchRoleMutation,
  useDeleteRoleMutation,
  useGetModulesByCodeQuery,
  useLazyGetModulesByCodeQuery,
} = rolesApi;
