import { Container, Typography } from "@mui/material";

import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);

interface GraficoBarrasGenericoProps {
  title?: string;
  subtitle?: string;
  // data: Array<number>;
  datasets: Array<{ data: Array<number>; backgroundColor?: string; hidden?: boolean }>;
  labels: Array<any>;
  color: string;
  stacked?: boolean;
  showDataLabels?: boolean;
  isLoading?: boolean;
  labelFormatter?: (value: any, ctx: any) => string;
}

export const GraficoBarrasHorizontalGenerico = (props: GraficoBarrasGenericoProps) => {
  const data: ChartData<"bar"> = {
    labels: props.labels,
    datasets: props.datasets,
  };

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: props.showDataLabels ?? false,
        // color: "#0070c0",
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },

        formatter:
          props.labelFormatter ??
          function (value, _) {
            return value;
          },
      },
      annotation: {
        annotations: {
          label1: {
            type: "line",
            value: 0,
            borderWidth: 0,
            drawTime: "afterDatasetsDraw",
            display: (ctx: any) => {
              if (props.isLoading) return false;
              const datasets = ctx.chart.data.datasets;
              const result = datasets
                .map((dataset: any) => {
                  return dataset.data.length > 0;
                })
                .some((value: boolean) => value === true);
              return !result;
            },
            label: {
              content: ["Sin datos para mostrar"],
              display: true,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "#000",
              font: {
                size: 18,
              },
            },
          },
          isLoading: {
            type: "line",
            value: 0,
            borderWidth: 0,
            drawTime: "afterDatasetsDraw",
            display: props.isLoading,
            label: {
              content: ["Cargando información..."],
              display: true,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "#000",
              font: {
                size: 18,
              },
            },
          },
        },
      },
    },
    scales: {
      x: {
        display: false,
        stacked: props.stacked,
        grid: {
          display: false,
        },
        max: Math.max(...(props.datasets.at(0)?.data ?? [])) + Math.max(...(props.datasets.at(0)?.data ?? [])) / 4,
      },
      y: {
        stacked: props.stacked ?? false,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#0d305c",
          // padding: 50,
          // callback: function (value, index, ticks) {
          //   return value;
          // },
          // font: {
          // weight: "bold",
          // size: 14,
          // },
        },
      },
    },
  };
  return (
    <>
      <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
        {props.title}
        <Typography component={"span"} variant="h5" fontWeight={400} color="#002652">
          {" "}
          {props.subtitle}
        </Typography>
      </Typography>
      <Container style={{ position: "relative", height: "45vh" }}>
        {/* {props.data} */}
        <Bar data={data} options={options} />
      </Container>
    </>
  );
};
