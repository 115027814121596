import { useState } from "react";
import {
  Box,
  Button,
  Grid2 as Grid,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { setDialogConfig } from "@store/slices/componentsSlice";
import { DialogType } from "@interfaces/slices";
import {
  useLazyGetConditionQuery,
  usePostCommentByAppMutation,
  usePostConditionMutation,
  usePostReasonRejectAppMutation,
  usePostStatusApplicationMutation,
  usePutConditionMutation,
} from "@services/api/applications";
import { setApplicationStateCode } from "@store/slices/appSlice";
import { useLazyGetReasonRejectionAppQuery } from "@services/api/catalogs";
import { useLazyGetModulesByCodeQuery } from "@services/api/roles";

export interface RevisionSegmentProps {
  type?: "Authorize" | "Reject" | "Block";
  onBackClick: () => void;
  onConfirmClick: () => void;
}

export const Revision = (props: RevisionSegmentProps) => {
  const dispatch = useDispatch();

  const [optionSelected, setOptionSelected] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [disabledOkBtn, setDisabledOkBtn] = useState<boolean>(true);

  const userSession = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const [triggerPutCondition] = usePutConditionMutation();
  const [triggerGetCondition] = useLazyGetConditionQuery();
  const [triggerPostCondition] = usePostConditionMutation();
  const [triggerPostComment] = usePostCommentByAppMutation();
  const [triggerGetModulesByCode] = useLazyGetModulesByCodeQuery();
  const [triggerPostReasonReject] = usePostReasonRejectAppMutation();
  const [triggerGetReasonReject] = useLazyGetReasonRejectionAppQuery();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const levels = [
    {
      montoMax: 3000000,
      plazoMax: 30,
      tasa: 28,
      riesgo: "A1",
    },
    {
      montoMax: 3000000,
      plazoMax: 34,
      tasa: 30,
      riesgo: "A2",
    },
    {
      montoMax: 2000000,
      plazoMax: 21,
      tasa: 32,
      riesgo: "A3",
    },
    {
      montoMax: 1500000,
      plazoMax: 18,
      tasa: 34,
      riesgo: "A4",
    },
    {
      montoMax: 1000000,
      plazoMax: 18,
      tasa: 36,
      riesgo: "A5",
    },
  ];

  const handleOnBackClicked = () => {
    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
    props.onBackClick?.();
  };

  const handleOnOkClicked = async () => {
    setIsProcessing(true);

    if (props.type === "Authorize") {
      try {
        const module = await triggerGetModulesByCode("SBFLRE").unwrap();

        await triggerPostComment({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          payload: {
            comment: `Motivo de autorización: ${reason}`,
            user_id: userSession.id,
            module_id: module?.id,
          },
        }).unwrap();

        const prevCondition = await triggerGetCondition({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          conditionId: applicationSelected.loanApplicationConditionActive.id ?? "0-0-0-0-0",
        }).unwrap();

        const level = levels.find((t) => t.riesgo == optionSelected);

        await triggerPutCondition({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          conditionId: applicationSelected.loanApplicationConditionActive.id ?? "0-0-0-0-0",
          body: {
            active: false,
          },
        }).unwrap();

        await triggerPostCondition({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          body: {
            loan_amount: prevCondition.loan_amount,
            approved_amount: prevCondition.approved_amount,
            requested_term: prevCondition.requested_term,
            approved_term: prevCondition.approved_term,
            interest_rate: level?.tasa,
            resiudal_value: prevCondition.residual_value,
            monthly_payment: prevCondition.monthly_payment,
            score: prevCondition.score,
            score_code: prevCondition.score_code,
          },
        }).unwrap();

        if (applicationSelected.status_code !== "CSAAO") {
          await triggerPostStatusApplication({
            applicationId: applicationSelected.id ?? "0-0-0-0-0",
            userName:
              userSession?.name?.concat(" ", userSession.last_name ?? "", " ", userSession.last_name_2 ?? "").trim() ??
              "",
            statusCode: "CSAAO",
            disableSequenceCheck: true,
          }).unwrap();

          dispatch(setApplicationStateCode("CSAAO"));
        }
      } catch (error) {
      } finally {
        setIsProcessing(false);
        props.onConfirmClick?.();
      }
    }

    if (props.type === "Reject") {
      try {
        const module = await triggerGetModulesByCode("SBFLRE").unwrap();
        await triggerPostComment({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          payload: {
            comment: `Motivo de rechazo: ${reason}`,
            user_id: userSession.id,
            module_id: module?.id,
          },
        }).unwrap();

        const reasons = await triggerGetReasonReject().unwrap();
        const reasonReject = reasons.find((t) => t.code === "CRRZG");

        await triggerPostReasonReject({
          applicationId: applicationSelected.id,
          payload: [
            {
              reason_rejection_id: reasonReject?.id ?? "0-0-0-0-0",
            },
          ],
        }).unwrap();

        await triggerPostStatusApplication({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          userName:
            userSession.name?.concat(" ", userSession.last_name ?? "", " ", userSession.last_name_2 ?? "").trim() ?? "",
          statusCode: "CSREC",
          disableSequenceCheck: true,
        }).unwrap();

        dispatch(setApplicationStateCode("CSREC"));
      } catch (error) {
      } finally {
        setIsProcessing(false);
        props.onConfirmClick?.();
      }
    }

    if (props.type === "Block") {
      try {
        await triggerPostComment({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          payload: {
            comment: `Motivo de bloqueo: ${reason}`,
            user_id: userSession.id,
          },
        }).unwrap();

        const reasons = await triggerGetReasonReject().unwrap();
        const reasonBlock = reasons.find((t) => t.code === "CRBZG");

        await triggerPostReasonReject({
          applicationId: applicationSelected.id,
          payload: [
            {
              reason_rejection_id: reasonBlock?.id ?? "0-0-0-0-0",
            },
          ],
        }).unwrap();

        await triggerPostStatusApplication({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          userName:
            userSession.name?.concat(" ", userSession?.last_name ?? "", " ", userSession?.last_name_2 ?? "").trim() ??
            "",
          statusCode: "CSREC",
          disableSequenceCheck: true,
        }).unwrap();
      } catch (error) {
      } finally {
        setIsProcessing(false);
        props.onConfirmClick?.();
      }
    }
  };

  const handleOnOptionSelected = (e: any) => {
    setOptionSelected(e.target.value);

    setDisabledOkBtn(reason.trim().length === 0);
  };

  const handleOnReasonChanged = (e: any) => {
    setReason(e.target.value);
    setDisabledOkBtn(
      props.type === "Authorize"
        ? e.target.value.trim().length === 0 || optionSelected.trim().length === 0
        : e.target.value.trim().length === 0,
    );
  };

  const createRow = (
    riesgo: string,
    montoMax: string,
    plazoMax: string,
    tasa: string,
    hiddenBottomBorder: boolean = false,
  ) => {
    return (
      <TableRow selected={optionSelected == riesgo}>
        <TableCell padding="none" sx={{ borderBottom: `${hiddenBottomBorder ? 0 : 1}px solid #A3D4E8` }}>
          <Radio value={riesgo} onClick={handleOnOptionSelected} checked={optionSelected == riesgo} />
        </TableCell>
        <TableCell align="center" sx={{ borderBottom: `${hiddenBottomBorder ? 0 : 1}px solid #A3D4E8` }}>
          <Typography variant="body2" color={"main3"} fontWeight={700} sx={{ opacity: 0.5 }}>
            {riesgo}
          </Typography>
        </TableCell>
        {/* <TableCell align="center" sx={{ borderBottom: `${hiddenBottomBorder ? 0 : 1}px solid #A3D4E8` }}>
          <Typography variant="body2">{montoMax} M</Typography>
        </TableCell>
        <TableCell align="center" sx={{ borderBottom: `${hiddenBottomBorder ? 0 : 1}px solid #A3D4E8` }}>
          <Typography variant="body2">{plazoMax}</Typography>
        </TableCell> */}
        <TableCell align="center" sx={{ borderBottom: `${hiddenBottomBorder ? 0 : 1}px solid #A3D4E8` }}>
          <Typography variant="body2">{tasa}%</Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box margin={4}>
      <Stack textAlign={"center"}>
        <Typography variant="body1" fontWeight={600}>
          Está seguro que quiere{" "}
          <Typography
            component={"span"}
            fontWeight={600}
            color={props.type === "Authorize" ? "secondary4" : "secondary3"}
          >
            {props.type === "Authorize" && "autorizar"}
            {props.type === "Reject" && "rechazar"}
            {props.type === "Block" && "bloquear"}
          </Typography>{" "}
          a:
        </Typography>
        <Typography variant="body1" fontWeight={600} color="main2" mt={2}>
          {applicationSelected.razonSocial}
        </Typography>
        <Typography variant="body1" color="main2">
          ID SOLICITUD:{" "}
          <Typography variant="body1" fontWeight={600} component={"span"}>
            {applicationSelected.solicitudId}
          </Typography>
        </Typography>
        <Typography variant="body1" fontWeight={400} color="main3" mt={2}>
          Provea de una breve descripción del motivo
        </Typography>
        <Typography variant="body1" fontWeight={400} color="main3" mb={3}>
          de {props.type == "Authorize" ? "autorización" : "rechazo"} en la reconsideración.
        </Typography>
        <TextField
          multiline
          rows={5}
          fullWidth
          label={"Breve descripción del motivo"}
          onChange={handleOnReasonChanged}
        />
        {props.type === "Authorize" && (
          <>
            <Typography variant="body1" fontWeight={400} color="main3" mt={3}>
              Que nivel de riesgo/tasa otorga al cliente:
            </Typography>
            <Box px={4} mx={5}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ borderBottom: "0px solid #A3D4E8" }}></TableCell>
                    <TableCell align="center" sx={{ borderBottom: "0px solid #A3D4E8" }}>
                      <Typography variant="body2" fontWeight={700} color="main3" sx={{ opacity: 0.8 }}>
                        Riesgo
                      </Typography>
                    </TableCell>
                    {/* <TableCell align="center" sx={{ borderBottom: "0px solid #A3D4E8" }}>
                    <Typography variant="body2" fontWeight={700} color="main3" sx={{ opacity: 0.8 }}>
                      Monto Max
                    </Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "0px solid #A3D4E8" }}>
                    <Typography variant="body2" fontWeight={700} color="main3" sx={{ opacity: 0.8 }}>
                      Plazo Max
                    </Typography>
                  </TableCell> */}
                    <TableCell align="center" sx={{ borderBottom: "0px solid #A3D4E8" }}>
                      <Typography variant="body2" fontWeight={700} color="main3" sx={{ opacity: 0.8 }}>
                        Tasa
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {createRow("A1", "3", "30", "30")}
                  {createRow("A2", "3", "24", "31")}
                  {createRow("A3", "2", "21", "32")}
                  {createRow("A4", "1.5", "18", "34")}
                  {createRow("A5", "1", "18", "36", true)}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
        <Grid
          container
          flexDirection={"row"}
          justifyContent={"space-around"}
          alignItems={"center"}
          marginTop={5}
          columnSpacing={2}
        >
          <Grid>
            <Button variant={"blue_outlined"} onClick={handleOnBackClicked}>
              Regresar
            </Button>
          </Grid>
          <Grid>
            <Button
              type="submit"
              variant={"contained"}
              color={props.type === "Authorize" ? "secondary4" : "secondary3"}
              loading={isProcessing}
              disabled={disabledOkBtn}
              onClick={handleOnOkClicked}
            >
              {props.type === "Authorize" && "Autorizar"}
              {props.type === "Reject" && "Rechazar"}
              {props.type === "Block" && "Bloquear"}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
