import { ClickAwayListener, Container, Grid2 as Grid, LinearProgress, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { useDispatch, useSelector } from "react-redux";

import { PopupType } from "@interfaces/slices";
import { ReviewStatus } from "@interfaces/application";
import { setPopupConfig } from "@store/slices/componentsSlice";

import DownloadWaitIcon from "@assets/download_wait_icon.svg";
import DownloadWarningIcon from "@assets/download_warning_icon.svg";
import { useEffect, useState } from "react";
import { RootState } from "@store/store";
import { useLazyGetCompanyProfileQuery } from "@services/api/applications";

interface EstatusRazonesRevProps {
  // status: ReviewStatus | undefined;
}

export const EstatusRazonesRevision = (props: EstatusRazonesRevProps) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [reloading, setReloading] = useState<boolean>(false);
  const [status, setStatus] = useState<ReviewStatus>();

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [triggerGetCompanyProfile] = useLazyGetCompanyProfileQuery();

  const handleOnAbortiveDetailClicked = (e: any, details: any) => {
    const abortiveType = e.target.dataset.abortive;
    dispatch(
      setPopupConfig({
        type: PopupType.DETALLE_ABORTIVO,
        anchorEl: abortiveType,
        props: {
          details: details,
        },
      }),
    );
  };

  const createSectionStatus = (title: string, status: boolean | null, details: any, addMenu: boolean = false) => {
    const tmp = title.replace(" ", "_").replace(".", "").concat("_err_").toLowerCase();
    return (
      <Grid>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: addMenu ? "pointer" : "default",
          }}
          onClick={
            addMenu
              ? (e) => {
                  handleOnAbortiveDetailClicked(e, details);
                }
              : () => {}
          }
          data-abortive={tmp}
        >
          {status === false && <CheckIcon sx={{ color: "#528CD6", width: 13, height: 11 }} />}
          {status === true && <img src={DownloadWarningIcon} />}
          {status === null && <img src={DownloadWaitIcon} />}
          <Typography variant="body3" fontWeight={500} color="#002652" mt={0.2} data-abortive={tmp}>
            {title}
          </Typography>
          <Typography
            variant="body3"
            fontWeight={500}
            color={status === true ? "main4" : status === null ? "secondary4" : "main2"}
            data-abortive={tmp}
            id={`${tmp}_status`}
          >
            {status === false ? "Cumple" : status === null ? "Procesando" : "No Cumple"}
          </Typography>
        </Container>
      </Grid>
    );
  };

  const validateState = (section: any): boolean | null => {
    if (section === undefined || section === null) return null;

    const keys = Object.keys(section);
    const withoutAllAbortives = keys.filter((key) => key !== "show_menu").every((t) => section[t] === false);
    const withAllAbortives = keys.filter((key) => key !== "show_menu").every((t) => section[t] === true);
    const withAllProcessing = keys.filter((key) => key !== "show_menu").every((t) => section[t] === null);

    if (withoutAllAbortives) return false;
    if (withAllAbortives) return true;
    if (withAllProcessing) return null;

    const someProcessing = keys.filter((key) => key !== "show_menu").some((t) => section[t] === null);
    if (someProcessing) return null;

    const someAbortive = keys.filter((key) => key !== "show_menu").some((t) => section[t] === true);
    if (someAbortive) return true;

    return false;
  };

  const requestData = async () => {
    try {
      setReloading(false);

      const result = await triggerGetCompanyProfile({
        rfc: applicationSelected.rfc,
        loanApplicationId: applicationSelected.id ?? "0-0-0-0-0",
      }).unwrap();

      setStatus(result.review_status);

      if (printingMode === false) {
        const reloading = Object.keys(result.review_status).some((key) => {
          let bFlag = null;
          if (key === "show_menu") {
            bFlag = true;
          } else {
            bFlag = Object.keys(result.review_status[key]).some((b) => result.review_status[key][b] !== null);
          }
          return bFlag === false;
        });
        setReloading(reloading);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (applicationSelected.id === "0-0-0-0-0" || applicationSelected.id === undefined) return;

    setLoading(true);
    requestData();
  }, [applicationSelected.id]);

  useEffect(() => {
    if (reloading === false) return;

    setTimeout(() => {
      setLoading(true);
      requestData();
    }, 2500);
  }, [reloading]);

  return (
    // <ClickAwayListener onClickAway={handleOnClose}>
    <Grid
      pt={2}
      rowSpacing={2}
      container
      sx={{ backgroundColor: "#E3F2F8", borderRadius: "8px" }}
      justifyContent={"space-between"}
    >
      {createSectionStatus("Ventas", validateState(status?.sales), status?.sales, status?.sales?.show_menu ?? false)}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus(
        "Cap. Contable",
        validateState(status?.declaration),
        status?.declaration,
        status?.declaration?.show_menu ?? false,
      )}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus(
        "Buró Acred",
        validateState(status?.credit_bureau_accredited),
        status?.credit_bureau_accredited,
        status?.credit_bureau_accredited?.show_menu ?? false,
      )}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus(
        "Ant. Actividad",
        validateState(status?.seniority),
        status?.seniority,
        status?.seniority?.show_menu ?? false,
      )}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus(
        "Edad Acc.",
        validateState(status?.shareholder_or_pfae_age),
        status?.shareholder_or_pfae_age,
        status?.shareholder_or_pfae_age?.show_menu ?? false,
      )}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Zona", validateState(status?.zone), status?.zone, status?.zone?.show_menu ?? false)}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus(
        "Op.Cump",
        validateState(status?.tax_compliance),
        status?.tax_compliance,
        status?.tax_compliance?.show_menu ?? false,
      )}

      <Grid size={12} mx={0}>
        <LinearProgress sx={{ borderRadius: 25, visibility: loading ? "visible" : "hidden" }} />
      </Grid>
    </Grid>
    // </ClickAwayListener>
  );
};
