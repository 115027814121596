import { UUID } from "crypto";
import { DocumentInfo } from "./documents";

export enum DialogType {
  NONE,
  RECHAZAR_DOC,
  VALIDAR_DOC,
  VALIDAR_EXP,
  VALIDAR_DIC,
  BORRAR_DOC,

  AGENDAR_VISITA,
  RECHAZAR_APP,
  RECHAZAR_BLOQUEAR_APP,
  DOCS_FALTANTES,
  ESTATUS_NOTIFICACION,
  DESCARGAR_REPORTE,

  REVISION_AUTORIZAR,
  REVISION_RECHAZAR,
  REVISION_RECHAZAR_BLOQUEAR,
  COMENTARIOS,
}

export enum PopupType {
  NONE,
  DETALLE_ABORTIVO,
}

export enum ButtonType {
  VALIDAR_AUTORIZACION,
  RECHAZAR,
  RECHAZAR_BLOQUEAR,
  VALIDAR_DICTAMEN,
  DOCS_FALTANTES,
  VALIDAR_EXPEDIENTE,
  VALIDAR_VISITA,
  DESCARGAR_EXPEDIENTE,
  VOLVER_VISITAR,
  LIBERAR_DISPERSION,
  CONVERTIR_CLIENTE,
  COMENTARIOS,
  VALIDAR_CONTRON,
  REGRESAR_AREA,
  VALIDAR_GENERICO,
  VALIDACION_CONTROL,
  GUARDAR,
  GENERAR_CONTRACTUALES,
  VALIDAR_FIRMA_CLIENTE,
}

export enum OptionApplicationMenu {
  INFO_GRAL,
  PERFIL_CREDITICIO,
  DICTAMEN_LEGAL,
  // PERFIL,
  // DICTAMEN,
  EXPEDIENTE,
  VALIDACION_CREDITICA,
  LEGAL_PLD,
  VISITA_OCULAR,
  REVISION_CONTROL,
  CONTRACTUALES,
  DISPERSION,
  RESUMEN_OPERACION,

  USUARIOS,
  ROLES_PERMISOS,
  MODULOS,
  CODIGOS_ATRIBUCION,
}

export enum DocumentAction {
  SEE,
  DOWNLOAD,
  UPLOAD,
  DELETE,
  VALIDATE,
  VALIDATE_TO_SIGN,
  REJECT,
  REJECT_RELOAD,
  VALIDATE_SIGN,
  REJECT_SIGN,
  SEND_MIFIEL,
  CLIENT_SIGN,
  INTERNAL_SIGN,
  LEGAL_INCIDENT_SELECTED,
}

export interface ApplicationSelected {
  id: UUID | undefined;
  solicitudId: number;
  razonSocial: string;
  nombreComercial: string;
  rfc: string;
  nombre: string;
  montoSolicitado: string;
  plazoSolicitado: number;
  montoContratado: string;
  plazoContratado: number;
  tiempoPantalla: number;
  tiempoTotal: number;
  tiempoTotalProceso: string;
  etapa: string;
  subEtapa: string;
  estatus: string;
  pantalla: string;
  macroCanal: string;
  canal: string;
  asignacion: string;
  perdioInteres: number;
  dictamenCompletado: boolean;
  canalId: string;
  correoEjecutivo: string;
  fullData: string;
  company_docs_validated: boolean;
  shareholder_docs_validated: boolean;
  owners_docs_validated: boolean;
  tax_systems: {
    id: UUID | undefined;
    code: string;
    name: string;
    type: string;
  };
  status_code: string;
  existsSDC: boolean;
  montoSolicitadoOriginal: number;
  plazoSolicitadoOriginal: number;
  montoContratadoOriginal: number;
  plazoContratadoOriginal: number;
  usuario: {
    nombre: string;
    correo: string;
  };
  accionistaMayoritario: {
    nombre: string;
    rfc: string;
  };
  clientDetailId: UUID | undefined;
  productId: UUID | undefined;
  statusId: UUID | undefined;
  fechaModelo: string;
  loanApplicationConditionActive: {
    id: UUID | undefined;
  };
  detalleCanal: string;
  credit_validated: boolean | null;
  pld_validated: boolean | null;
  legal_incidents_validated: boolean | null;
  visit_analysis_validated: boolean | null;
  check_control_validated: boolean | null;
}

export interface RejectDialogProps {
  document: DocumentInfo;
}

export interface ValidateDialogProps {
  title: string;
}

export interface StatusNotificationDialogProps extends ValidateDialogProps {
  iconType: "success" | "error" | "warning" | "info" | "none";
}

export interface CommentsDialogProps {
  title: string;
  subtitle: string;
  moduleCode: string;
  metadata?: any;
}

export interface ValidateVisitDialogProps {
  title: string;
  date: string;
  time: string;
}

export interface DialogConfig {
  shown: boolean;
  fullscreen?: boolean;
  type: DialogType;
  props:
    | undefined
    | RejectDialogProps
    | ValidateDialogProps
    | ValidateVisitDialogProps
    | StatusNotificationDialogProps
    | CommentsDialogProps;
  isProcessing?: boolean;
  onRejectClick?: () => void;
  onAcceptClick?: () => void;
  onCloseClick?: () => void;
}

export interface AbortivePopupProps {
  details: any;
}

export interface PopupConfig {
  type: PopupType;
  props: undefined | AbortivePopupProps;
  // anchorEl: HTMLElement | null | undefined;
  anchorEl: string | null | undefined;
}

export interface Shareholder {
  id: UUID;
  name: string;
  lastName: string;
  lastName2: string;
  rfc: string;
  totalValue: number;
  percent: number;
  legalAgent: boolean;
  majorityShareholder: boolean;
  actualOwner: boolean;
  toAdd: boolean;
  toDelete: boolean;
  toUpdate: boolean;
  isNew: boolean;
  personTypeCode: string;
}
