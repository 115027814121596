import { Box, Container, Grid2 as Grid, Stack, Typography } from "@mui/material";

import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import annotationPlugin from "chartjs-plugin-annotation";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);
Chart.register(annotationPlugin);

interface GraficoDonaGenericoProps {
  title: string;
  data: Array<number>;
  labels: Array<string>;
  colors: Array<string>;
  labelFormatter?: (value: any, ctx: any) => string;
  isLoading?: boolean;
}

export const GraficoDonaGenerico = (props: GraficoDonaGenericoProps) => {
  const data: ChartData<"doughnut"> = {
    labels: props.data,
    datasets: [{ label: "", data: props.data, backgroundColor: props.colors }],
  };

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: "#fff",
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        align: "center",
        textAlign: "center",
        clamp: true,
        display: (ctx) => {
          const total = ctx.dataset.data.reduce((acc: any, cv) => acc + cv, 0);
          return (Number(ctx.dataset.data[ctx.dataIndex]) * 100) / total > 5;
        },
        clip: true,
        formatter:
          props.labelFormatter ??
          function (value, _) {
            return value;
          },
      },
      annotation: {
        annotations: {
          label1: {
            type: "line",
            value: 0.5,
            borderWidth: 0,
            drawTime: "afterDatasetsDraw",
            display: (ctx: any) => {
              if (props.isLoading) return false;
              const datasets = ctx.chart.data.datasets;
              const result = datasets
                .map((dataset: any) => {
                  return dataset.data.length > 0;
                })
                .some((value: boolean) => value === true);
              return !result;
            },
            label: {
              content: ["Sin datos para mostrar"],
              display: true,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "#000",
              font: {
                size: 18,
              },
            },
          },
          isLoading: {
            type: "line",
            value: 0.5,
            borderWidth: 0,
            drawTime: "afterDatasetsDraw",
            display: props.isLoading,
            label: {
              content: ["Cargando información..."],
              display: true,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "#000",
              font: {
                size: 18,
              },
            },
          },
        },
      },
    },
  };

  return (
    <Grid container ml={1}>
      <Grid size={12}>
        <Typography variant="h4" fontWeight={600} color={"#002652"}>
          {props.title}
        </Typography>
      </Grid>
      <Grid size={6}>
        <Stack height={"100%"} pl={2} sx={{ justifyContent: "space-around" }}>
          {props.labels.map((label, idx) => {
            return (
              <Box key={idx} display={"flex"}>
                <Typography
                  component={"span"}
                  mr={1}
                  sx={{ position: "relative", top: "-2px" }}
                  color={props.colors[idx]}
                >
                  ■
                </Typography>
                <Typography key={idx} color={"#002060"} fontWeight={600}>
                  {label}
                </Typography>
              </Box>
            );
          })}
        </Stack>
      </Grid>
      <Grid size={6}>
        <Container>
          <Doughnut data={data} options={options} />
        </Container>
      </Grid>
    </Grid>
  );
};
