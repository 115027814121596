import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid2 as Grid, IconButton, Stack, Typography } from "@mui/material";

import dayjs from "dayjs";

import { RootState } from "@store/store";
import { formatCurrency } from "@helpers/formats";
import { createRowTable } from "@helpers/components";
import { EstatusDescargasSat } from "@components/EstatusDescargasSat";

import { setDialogConfig, setPrintingGeneralReady, setPrintingMode } from "@store/slices/componentsSlice";
import { DialogType } from "@interfaces/slices";

import {
  useLazyGetCompanyProfileQuery,
  useLazyGetConditionQuery,
  useLazyGetConditionsQuery,
  usePutApplicationsMutation,
} from "@services/api/applications";
import { SelectorAutocompletado } from "@components/SelectorAutocompletado";
import { useLazyGetCodesQuery } from "@services/api/codes";

import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@assets/download_icon.svg";
import { setApplicationCanalIdUpdated } from "@store/slices/appSlice";

export const General = () => {
  const dispatch = useDispatch();

  const userSession = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [resultSelected, setResultSelected] = useState<string>("");
  const [editChanelId, setEditChanelId] = useState<boolean>(false);

  // const [authorizedTerm, setAuthorizedTerm] = useState<number>(applicationSelected.plazoContratadoOriginal);
  // const [authorizedAmount, setAuthorizedAmount] = useState<number>(applicationSelected.montoContratadoOriginal);

  // const [triggerGetCodeById] = useLazyGetCodeByIdQuery();

  const [triggerPutApplication] = usePutApplicationsMutation();

  const [triggerGetCompanyProfile, resultGetCompanyProfile] = useLazyGetCompanyProfileQuery();
  const [triggerGetCurrentCondition, currentConditionResult] = useLazyGetConditionsQuery();

  const getApplicationResult = () => {
    let result = "";
    if (
      [
        "CSEVE",
        "CSVEX",
        "CSAVC",
        "CSVYC",
        "CSVVC",
        "CSEVD",
        "CSFIR",
        "CSVCF",
        "CSDIS",
        "CSAAO",
        "CSBEX",
        "CSDIC",
        "CSDOC",
        "CSCDO",
        "CDOEM",
        "CSAED",
        "CSECI",
        "CSEDC",
        "CSEDV",
        "CSVCL",
      ].includes(applicationSelected.status_code)
    ) {
      result = "Autorizado";
    }

    if (["CSREC", "CSRDO"].includes(applicationSelected.status_code)) {
      result = "Rechazado";
    }

    return result;
  };

  const handleOnDownloadReportClicked = () => {
    dispatch(setPrintingMode(true));
    dispatch(
      setDialogConfig({
        shown: true,
        fullscreen: true,
        type: DialogType.DESCARGAR_REPORTE,
        props: undefined,
      }),
    );
  };

  const handleOnEditChannelCanceled = () => {
    setEditChanelId(false);
  };

  const handleOnEditChannelAccepted = async (option: any) => {
    try {
      if (option === undefined) return;

      // console.log(option);
      await triggerPutApplication({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        body: {
          source_code: option.value,
          user_id_modified: userSession.id,
          user_name:
            userSession.name?.concat(" ", userSession?.last_name ?? "", " ", userSession?.last_name_2 ?? "").trim() ??
            "",
        },
      });

      // const newCode = await triggerGetCodeById(option.id).unwrap();
      // console.log(newCode);
      dispatch(setApplicationCanalIdUpdated(option.value));
    } catch (error) {
      console.error(`No se pudo actualizar el canal: ${error}`);
    } finally {
      setEditChanelId(false);
    }
  };

  useEffect(() => {
    setResultSelected(getApplicationResult());
    if (printingMode) {
      dispatch(setPrintingGeneralReady(resultGetCompanyProfile.isSuccess));
    }
  }, [resultGetCompanyProfile]);

  useEffect(() => {
    if (applicationSelected.id === "0-0-0-0-0" || applicationSelected.id === undefined) return;

    triggerGetCompanyProfile({
      rfc: applicationSelected.rfc,
      loanApplicationId: applicationSelected.id,
    });

    const searchParams = new URLSearchParams();
    searchParams.append("q", "active:true");
    triggerGetCurrentCondition({
      applicationId: applicationSelected.id,
      params: searchParams.toString(),
    });
  }, [applicationSelected.id]);

  // useEffect(() => {
  //   setAuthorizedAmount(applicationSelected.montoContratadoOriginal);
  //   setAuthorizedTerm(applicationSelected.plazoContratadoOriginal);
  // }, [applicationSelected.plazoContratadoOriginal, applicationSelected.montoContratadoOriginal]);

  return (
    <Grid container pt={1}>
      <Grid size={12} textAlign={"end"} mb={2} display={printingMode ? "none" : "flex-end"}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>

      <Grid container direction={"column"}>
        <Grid container sx={{ justifyContent: "space-between" }} rowSpacing={4} columnSpacing={2}>
          <Grid size={printingMode ? { sm: 8 } : {}}>
            <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
              Descargas
            </Typography>
            <EstatusDescargasSat />
          </Grid>
          <Grid size={printingMode ? { sm: 4 } : {}}>
            <Typography variant="h4" fontWeight={600} color={"#002652"} mb={1}>
              Etapa
            </Typography>
            <Box pr={5} minWidth={"350px"}>
              <Grid container textAlign={"center"} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Etapa
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Subetapa
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Estatus/Pantalla
                  </Typography>
                </Grid>
              </Grid>
              <Grid container textAlign={"center"}>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#528CD6" pt={1}>
                    {applicationSelected.etapa}
                  </Typography>
                </Grid>
                <Grid
                  size={4}
                  borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
                  borderRight={"1px solid rgba(163, 212, 232, 0.5)"}
                >
                  <Typography variant="body2" pt={1}>
                    {applicationSelected.subEtapa}
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" pt={1}>
                    {applicationSelected.estatus}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={2} mb={1}>
          Modelo
        </Typography>
        <Grid container>
          {createRowTable(
            "ID Solicitud",
            applicationSelected.solicitudId.toString(),
            "ID Modelo",
            resultGetCompanyProfile.currentData?.result_model.id_model ?? "",
          )}
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Resultado
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={resultSelected == "Autorizado" ? "#01A388" : "#E35050"}>
                    {resultSelected}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    RFC
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>{applicationSelected.rfc}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {createRowTable(
            "Calificación Score Lendia:",
            currentConditionResult.isSuccess ? currentConditionResult.currentData?.at(0)?.score_code ?? "" : "",
            "Score",
            formatCurrency(Number(resultGetCompanyProfile.currentData?.result_model.score) ?? 0, "", 1),
          )}
          {createRowTable(
            "Monto Seleccionado:",
            formatCurrency(applicationSelected.montoSolicitadoOriginal ?? 0, "$", 0),
            "Fecha Inicio Solicitud",
            resultGetCompanyProfile.currentData?.created_at
              ? dayjs(resultGetCompanyProfile.currentData.date_loan_application)
                  .utc(true)
                  .local()
                  .locale("es-mx")
                  .format("DD/MMM/YYYY - HH:mm:ss")
              : "--/--/---- - --:--:--",
          )}
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Monto Máximo Autorizado
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }} container display={"flex"} flexDirection={"row"} columnGap={1}>
                  <Grid size={8}>
                    {currentConditionResult.isSuccess && (
                      <Typography fontWeight={600} color="#528CD6">
                        {formatCurrency(currentConditionResult.currentData?.at(0)?.approved_amount ?? 0, "$", 0)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Fecha Modelo
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>
                    {resultGetCompanyProfile.currentData?.result_model.date_model
                      ? dayjs(resultGetCompanyProfile.currentData?.result_model.date_model)
                          .utc(true)
                          .local()
                          .locale("es-mx")
                          .format("DD/MMM/YYYY - HH:mm:ss")
                      : "--/--/---- - --:--:--"}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {createRowTable(
            "Plazo seleccionado",
            `${applicationSelected.plazoSolicitadoOriginal ?? 0} meses`,
            "Días de Validez Modelo",
            resultGetCompanyProfile.currentData?.result_model.days_validity_model.toString() ?? "0",
          )}
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Plazo Máximo Autorizado
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }} container display={"flex"} flexDirection={"row"} columnGap={1}>
                  <Grid size={8}>
                    {currentConditionResult.isSuccess && (
                      <Typography fontWeight={600} color="#528CD6">
                        {currentConditionResult.currentData?.at(0)?.approved_term} meses
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    Tasa
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  {currentConditionResult.isSuccess && (
                    <Typography color={"#002652"}>
                      {`${formatCurrency(currentConditionResult.currentData?.at(0)?.interest_rate ?? 0, "", 1)}%`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Usuario y Accionista
        </Typography>
        <Grid container>
          {createRowTable(
            "Usuario Solicitud",
            applicationSelected.usuario.nombre,
            "Correo Usuario",
            applicationSelected.usuario.correo,
          )}
          {createRowTable(
            "Accionista Mayoritario",
            applicationSelected.accionistaMayoritario.nombre ?? "-",
            "RFC Accionista Mayoritario",
            applicationSelected.accionistaMayoritario.rfc ?? "-",
          )}
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Generales
        </Typography>
        <Grid container>
          {createRowTable(
            "Nombre Comercial",
            resultGetCompanyProfile.currentData?.general_data?.company_name ?? "",
            "Pagina Web",
            resultGetCompanyProfile.currentData?.general_data?.web_page ?? "",
          )}
          {createRowTable(
            "Linkedin",
            resultGetCompanyProfile.currentData?.general_data?.linkedin ?? "",
            "Facebook",
            resultGetCompanyProfile.currentData?.general_data?.facebook ?? "",
          )}
          {createRowTable(
            "Twitter / X",
            resultGetCompanyProfile.currentData?.general_data?.x_twitter ?? "",
            "Instagram",
            resultGetCompanyProfile.currentData?.general_data?.instagram ?? "",
          )}
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Origen
        </Typography>
        <Grid container>
          <Grid size={{ xs: 6 }} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"} p={1}>
            <div>
              <Grid container>
                <Grid size={{ xs: 6 }}>
                  <Typography variant="body1" color={"#0026528C"} fontWeight={700}>
                    ID Canal
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Stack direction={"row"} sx={{ alignItems: "center" }}>
                    {editChanelId === false && (
                      <>
                        <Typography color={"#002652"}>
                          {applicationSelected.canalId == "" ? "00000" : applicationSelected.canalId}
                        </Typography>
                        {userSession.role?.code === "RADM2" && (
                          <IconButton onClick={() => setEditChanelId(true)}>
                            <EditIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                    {editChanelId && (
                      <>
                        <SelectorAutocompletado
                          triggerFunc={useLazyGetCodesQuery}
                          fieldId={"id"}
                          fieldLabel={"code"}
                          onCancel={handleOnEditChannelCanceled}
                          onAccept={handleOnEditChannelAccepted}
                        />
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            size={{ xs: 6 }}
            p={1}
            borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
            borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}
          >
            <div>
              <Grid container ml={4}>
                <Grid size={{ xs: 6 }}>
                  <Typography component={"span"} variant="body1" color={"#0026528C"} fontWeight={700}>
                    Canal
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Typography color={"#002652"}>{applicationSelected.canal}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {createRowTable(
            "Macrocanal",
            applicationSelected.macroCanal,
            "Detalle Canal",
            applicationSelected.detalleCanal ?? "Orgánico",
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
