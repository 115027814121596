export const formatCurrency = (amount: number, currencySymbol = "$", fixed = 2) => {
  if (isNaN(amount)) {
    return `${currencySymbol}0`;
  }
  // Formatear el número con separadores de miles y dos decimales
  // const formattedAmount = amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // const formattedAmount = amount.toString().replace(/\d(?=(\d{3})+\.)/g, "$&,");
  // const formattedAmount = amount.toFixed(2);
  const formattedAmount = amount
    .toFixed(fixed)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Retornar el string formateado con el símbolo de la moneda
  return `${currencySymbol}${formattedAmount}`;
};

export const formatHours = (hours: number) => {
  const days = Math.floor(hours / 24);
  const hourRemaining = hours - days * 24;

  if (days !== 0) {
    if (days == 1) {
      return `${days} día ${hourRemaining} horas`;
    } else {
      return `${days} días ${hourRemaining} horas`;
    }
  }

  return `${hourRemaining} horas`;
};

export const formatYears = (months: number) => {
  const years = Math.floor(months / 12);
  const monthsRemaining = Math.floor(months - years * 12);

  let label = "";
  if (years !== 0) {
    label = years === 0 ? `${years} años` : years === 1 ? `${years} año` : `${years} años`;
    label += monthsRemaining === 0 ? "" : ", ";
    label += monthsRemaining === 0 ? "" : monthsRemaining === 1 ? `${monthsRemaining} mes` : `${monthsRemaining} meses`;
  }

  return label;
};

export const formatYearsV2 = (data: number) => {
  if (data === 0) {
    return "0 años";
  }
  const years = parseInt(data.toString());
  const monthsRemaining = Math.floor((data % 1) * 12);

  return `${years} ${years > 1 ? "años" : years === 0 ? " años" : " año"} ${monthsRemaining} ${
    monthsRemaining > 1 ? "meses" : monthsRemaining === 0 ? "meses" : "mes"
  }`;
};

export const replaceLastComma = (s: string) => {
  const lastIndex = s.lastIndexOf(",");

  if (lastIndex === -1) {
    return s;
  }

  return s.substring(0, lastIndex) + " y " + s.substring(lastIndex + 1);
};
