import { useEffect, useState } from "react";
import { Autocomplete, CircularProgress, IconButton, TextField } from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

interface SelectorAutocompletadoProps {
  triggerFunc: any;
  fieldId: string;
  fieldLabel: string;
  valueDefault?: string;
  paramsTriggerFunc?: any;
  onCancel: () => void;
  onAccept: (value: any | undefined) => void;
}

export const SelectorAutocompletado = (props: SelectorAutocompletadoProps) => {
  const [inputValue, setInputValue] = useState<string>();
  const [options, setOptions] = useState<Array<any>>([]);
  const [openCode, setOpenCode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [optionSelected, setOptionSelected] = useState<any>();

  const [trigger] = props.triggerFunc();

  const getCodes = async () => {
    try {
      if (inputValue === undefined || inputValue?.trim() === "") return;
      setLoading(true);
      setOpenCode(true);

      const results = await trigger(inputValue.toUpperCase()).unwrap();
      const items = results.map((t: any) => {
        return { id: t[props.fieldId], value: t[props.fieldLabel] };
      });
      setOptions(items);
    } catch (error) {
      console.error(`Al consultar: ${error}`);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleOnOpened = async () => {
    await getCodes();
  };

  const handleOnClosed = () => {
    setOpenCode(false);
    setOptions([]);
  };

  useEffect(() => {
    getCodes();
  }, [inputValue]);

  return (
    <>
      <Autocomplete
        size="small"
        fullWidth
        open={openCode}
        loading={loading}
        onOpen={handleOnOpened}
        onClose={handleOnClosed}
        onChange={(_, value, reason) => {
          if (reason == "selectOption") {
            setOptionSelected(value);
          }
        }}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.value}
        onInputChange={(_: any, newInputValue: any, reason) => {
          if (reason == "input") {
            setInputValue(newInputValue);
          }
        }}
        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Codigo"}
            slotProps={{
              input: {
                ...params.InputProps,
                inputProps: {
                  ...params.inputProps,
                  maxLength: 6,
                  style: { textTransform: "uppercase" },
                },
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              },
            }}
          />
        )}
        options={options}
      />

      <IconButton size="small" onClick={() => props.onAccept(optionSelected)}>
        <DoneIcon color="success" />
      </IconButton>
      <IconButton size="small" onClick={props.onCancel}>
        <ClearIcon color="error" />
      </IconButton>
    </>
  );
};
