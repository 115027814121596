import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Button, CircularProgress, Grid2 as Grid, Stack, TextField, Typography } from "@mui/material";

import dayjs from "dayjs";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { RootState } from "@store/store";
import { DialogType } from "@interfaces/slices";
import { Comment } from "@interfaces/application";
import { setDialogConfig } from "@store/slices/componentsSlice";
import { useLazyGetModulesByCodeQuery } from "@services/api/roles";
import { useLazyGetCommentsByModuleQuery, usePostCommentByAppMutation } from "@services/api/applications";

import "dayjs/locale/es-mx";

interface ComentariosProps {
  title: string;
  subtitle: string;
  moduleCode: string;
  metadata?: any;
  onBackClick: () => void;
  onConfirmClick: () => void;
}

export const Comentarios = (props: ComentariosProps) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentList, setCommentList] = useState<Array<Comment>>([]);
  const [withoutComments, setWithoutComments] = useState<boolean>(false);

  // const permissions = (state = store.getState()) => state.app.session.permissions;

  const userSession = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const [triggerGetModulesByCode] = useLazyGetModulesByCodeQuery();
  const [triggerGetCommentsByModule] = useLazyGetCommentsByModuleQuery();
  const [triggerPostComment] = usePostCommentByAppMutation();

  const getInitialsFromName = (user: any) => {
    let initials = "";
    if (user.name.concat(user.last_name).trim().length <= 3) {
      initials = user.name.substr(0, 3);
    }

    initials = user.name.substr(0, 1);
    initials += user.last_name.substr(0, 1);

    return initials;
  };

  const handleOnSaveCommentClicked = async () => {
    try {
      if (comment.trim().length === 0) return;

      setIsProcessing(true);
      const module = await triggerGetModulesByCode(props.moduleCode).unwrap();

      let commentToSave = comment.trim();
      if (props.moduleCode === "SBREFS") {
        commentToSave = JSON.stringify({ id: props.metadata["referenceId"], msg: comment });
      }

      await triggerPostComment({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        payload: {
          user_id: userSession.id,
          comment: commentToSave,
          module_id: module?.id,
        },
      }).unwrap();
    } catch (error) {
    } finally {
      setIsProcessing(false);
      await updateComments();
    }
  };

  const handleOnBackClicked = () => {
    setIsProcessing(false);
    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
    props.onBackClick();
  };

  const updateComments = async () => {
    try {
      setLoading(true);
      const module = await triggerGetModulesByCode(props.moduleCode).unwrap();
      const comments = await triggerGetCommentsByModule({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        moduleId: module?.id ?? "0-0-0-0-0",
      }).unwrap();

      let tmp = Array<Comment>();

      if (props.moduleCode === "SBREFS") {
        comments.forEach((comment) => {
          if (JSON.parse(comment.comment)["id"] === props.metadata["referenceId"]) {
            tmp.push({
              ...comment,
              comment: JSON.parse(comment.comment)["msg"],
            });
          }
        });
      } else {
        tmp = comments;
      }

      setCommentList(tmp);

      setWithoutComments(tmp.length === 0);
    } catch (error) {
      if ((error as FetchBaseQueryError).status === 404) {
        setWithoutComments(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (applicationSelected.id === "0-0-0-0-0" || applicationSelected.id === undefined) return;

    updateComments();
  }, [applicationSelected.id]);

  return (
    <Box margin={4} minWidth={400}>
      <Stack spacing={2}>
        <Typography variant="body1" fontWeight={600} textAlign={"center"}>
          {props.title}
        </Typography>
        <Typography variant="body2" fontWeight={400} textAlign={"center"}>
          {props.subtitle}
        </Typography>
        <TextField
          multiline
          fullWidth
          label={"Agregar comentario"}
          rows={5}
          onChange={(e) => setComment(e.target.value)}
        />
        <Grid container flexDirection={"row"} justifyContent={"end"} alignItems={"center"} columnGap={1}>
          <Grid>
            <Button variant={"blue_outlined"} onClick={handleOnBackClicked} disabled={isProcessing}>
              Cancelar
            </Button>
          </Grid>
          <Grid>
            <Button
              variant={"contained"}
              type="submit"
              loading={isProcessing}
              disabled={isProcessing}
              onClick={handleOnSaveCommentClicked}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
        <Typography variant="body1" fontWeight={400}>
          Comentarios:
        </Typography>
        {loading ? (
          <Box textAlign={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {commentList.map((comment, idx) => (
              <Box key={idx} borderBottom={"1px solid #A3D4E8"} pb={1}>
                <Grid container direction={"row"} columnGap={1}>
                  <Grid>
                    <Avatar>{getInitialsFromName(comment.user)}</Avatar>
                  </Grid>
                  <Grid container direction={"column"} size={"grow"}>
                    <Grid container direction={"row"} sx={{ justifyContent: "space-between" }} pr={1}>
                      <Typography variant="body2" fontWeight={400} color="main3">
                        {comment.user.name} {comment.user.last_name}
                      </Typography>
                      <Typography variant="body2" fontWeight={400} color="main3">
                        {dayjs(comment.created_at).utc(true).local().locale("es-mx").format("DD/MMM/YYYY HH:mm:ss")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="body1">{comment.comment}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ))}

            {withoutComments && (
              <Typography variant="body1" fontWeight={400} color="main3" textAlign={"center"}>
                Sin comentarios
              </Typography>
            )}
          </>
        )}
      </Stack>
    </Box>
  );
};
