import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Grid2 as Grid, Typography } from "@mui/material";

import { RootState, store } from "@store/store";
import { Options } from "@components/SolicitudSegmentos/Options";
// import { ButtonPanel } from "@components/SolicitudSegmentos/ButtonPanel";

import BackIcon from "@assets/back_icon.svg";
import {
  setOpinionModuleLegalAgentId,
  setOpinionModuleMajorityShareholder,
  setOpinionModuleValues,
  setShareholders,
} from "@store/slices/componentsSlice";
import { setCleanApplicationSelected } from "@store/slices/appSlice";

export const SolicitudLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  return (
    <>
      <Button
        variant="text"
        startIcon={<img src={BackIcon} />}
        sx={{
          color: "#F2704F",
          px: 1,
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent",
          },
          mb: 2,
        }}
        onClick={() => {
          dispatch(setShareholders([]));
          dispatch(setCleanApplicationSelected());
          dispatch(setOpinionModuleLegalAgentId("0-0-0-0-0"));
          dispatch(setOpinionModuleMajorityShareholder("0-0-0-0-0"));
          dispatch(
            setOpinionModuleValues({
              publicDeedNumber: "",
              publicDeedDate: "",
              notaryOfficeNumber: "",
              notaryName: "",
              municipality: "",
              federative: "",
              sufficientPowers: null,
              limitationContractCredit: null,
              commissionerName: "",
              commissionerLastName: "",
              commissionerLastName2: "",
              majorityShareholderValidate: null,
              legalAgentName: "",
              legalAgentLastName: "",
              legalAgentLastName2: "",
              legalAgentIsShareholder: null,
            }),
          );

          navigate("/");
        }}
      >
        Regresar a solicitudes
      </Button>
      <Grid container minHeight={"100%"} direction={"column"} mx={6} columns={24}>
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid size={4} minWidth={"200px"}>
            <Typography variant="h4" fontWeight={300} color="#002652">
              ID Solicitud:
              <Typography component={"span"} variant="h4" fontWeight={600} color="#002652">
                {applicationSelected.solicitudId.toString().padStart(5, "0")}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            size={"grow"}
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            minWidth={"800px"}
          >
            <Grid>
              <Typography variant="h4" fontWeight={600} color="#002652">
                {applicationSelected.rfc} - {applicationSelected.razonSocial} - {applicationSelected.nombreComercial}
              </Typography>
            </Grid>
            {/* <ButtonPanel /> */}
          </Grid>
        </Grid>
        <Grid container direction={"row"} minHeight={"100%"} minWidth={"200px"} mt={2}>
          <Grid size={4} container direction={"column"} minWidth={"200px"}>
            <Options />
          </Grid>
          <Grid size={"grow"}>
            <Suspense fallback={<></>}>
              <Outlet />
            </Suspense>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
