import { useEffect, useState } from "react";
import { Box, Container, Grid2 as Grid, LinearProgress, Typography } from "@mui/material";

import { RootState } from "@store/store";
import { useDispatch, useSelector } from "react-redux";

import { ModelStatus } from "@interfaces/model";
import { usePostModelDownloadStatusMutation } from "@services/api/sat_core";

// import DownloadOkIcon from "@assets/download_ok_icon.svg";
import CheckIcon from "@mui/icons-material/Check";
import DownloadWaitIcon from "@assets/download_wait_icon.svg";
import DownloadWarningIcon from "@assets/download_warning_icon.svg";
import { setPrintingDownloadSatReady } from "@store/slices/componentsSlice";

export const EstatusDescargasSat = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [reloading, setReloading] = useState<boolean>(false);

  const [status, setStatus] = useState<{
    facturas: ModelStatus;
    declaracion: ModelStatus;
    constancia: ModelStatus;
    opinion: ModelStatus;
    buro_empresa: ModelStatus;
    buro_persona: ModelStatus;
    info_laboral: ModelStatus;
  }>({
    facturas: ModelStatus.PENDING,
    declaracion: ModelStatus.PENDING,
    constancia: ModelStatus.PENDING,
    opinion: ModelStatus.PENDING,
    buro_empresa: ModelStatus.PENDING,
    buro_persona: ModelStatus.PENDING,
    info_laboral: ModelStatus.PENDING,
  });

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const printingMode = useSelector((state: RootState) => state.components.printingProfileReport.printingMode);

  const [triggerDownloadStatus] = usePostModelDownloadStatusMutation();

  const requestData = async () => {
    try {
      setReloading(false);
      const response = await triggerDownloadStatus({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        rfc: applicationSelected.rfc,
      }).unwrap();

      let status_info_laboral = ModelStatus.PENDING;
      if (response.job.imss === response.job.issste) {
        status_info_laboral = response.job.imss;
      } else if (response.job.imss === ModelStatus.DOWNLOADED || response.job.issste === ModelStatus.DOWNLOADED) {
        status_info_laboral = ModelStatus.DOWNLOADED;
      } else if (response.job.imss === ModelStatus.DOWNLOADING || response.job.issste === ModelStatus.DOWNLOADING) {
        status_info_laboral = ModelStatus.DOWNLOADING;
      }

      const resultStatus: any = {
        facturas: response.invoices.status,
        declaracion: response.annual_tax_return.status,
        constancia: response.tax_status.status,
        opinion: response.tax_compliance.status,
        buro_empresa: response.client_bureau.status,
        buro_persona: response.pf_bureau.status,
        info_laboral: status_info_laboral,
      };

      setStatus(resultStatus);

      if (printingMode === false) {
        const reloading = Object.keys(resultStatus).some(
          (key) => resultStatus[key] === ModelStatus.PENDING || resultStatus[key] === ModelStatus.DOWNLOADING,
        );
        setReloading(reloading);
      }
    } catch (error: any) {
      if (error.status !== 404) {
        console.log("EstatusDescargasSat: No se pudo consultar información: %s - %s", JSON.stringify(error), error);
      } else {
        if (printingMode === false) {
          setReloading(true);
        }
      }
    } finally {
      setLoading(false);
      if (printingMode) {
        dispatch(setPrintingDownloadSatReady(true));
      }
    }
  };

  useEffect(() => {
    if (applicationSelected.id === "0-0-0-0-0" || applicationSelected.id === undefined) return;

    setLoading(true);
    requestData();
  }, [applicationSelected.id]);

  useEffect(() => {
    if (reloading) {
      setTimeout(() => {
        setLoading(true);
        requestData();
      }, 2500);
    }
  }, [reloading]);

  const createSectionStatus = (title: string, status: ModelStatus) => {
    const texts = new Map([
      [ModelStatus.DOWNLOADED, "Descargado"],
      [ModelStatus.DOWNLOADING, "Descargando"],
      [ModelStatus.DUPLICATE, "Duplicado"],
      [ModelStatus.MISISNG, "Faltantes"],
      [ModelStatus.NO_HIT, "No hit"],
      [ModelStatus.PENDING, "Pendiente"],
    ]);

    return (
      <Grid>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
        >
          {status === ModelStatus.DOWNLOADED && <CheckIcon sx={{ color: "#528CD6", width: 13, height: 13 }} />}
          {status === ModelStatus.DOWNLOADING && <img src={DownloadWaitIcon} />}
          {(status === ModelStatus.MISISNG || status === ModelStatus.NO_HIT) && <img src={DownloadWarningIcon} />}
          {status === ModelStatus.PENDING && <Box height={10}></Box>}
          <Typography variant="body3" fontWeight={500} color="#002652" mt={0.2}>
            {title}
          </Typography>
          <Typography
            variant="body3"
            fontWeight={500}
            color={
              status === ModelStatus.MISISNG || status === ModelStatus.NO_HIT
                ? "#F2704F"
                : status === ModelStatus.DOWNLOADED
                ? "#528CD6"
                : "#01A388"
            }
          >
            {texts.get(status)}
          </Typography>
        </Container>
      </Grid>
    );
  };

  return (
    <>
      <Grid
        pt={2}
        rowSpacing={2}
        container
        sx={{ backgroundColor: "#E3F2F8", borderRadius: "8px" }}
        justifyContent={"space-between"}
      >
        {createSectionStatus("Facturas", status.facturas)}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus("Declaración", status.declaracion)}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus("Constancia", status.constancia)}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus("Opinión C.", status.opinion)}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus("Buró Empresa", status.buro_empresa)}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus("Buró Persona", status.buro_persona)}
        <Grid border={"1px solid #A3D4E8"}></Grid>
        {createSectionStatus("Info Laboral", status.info_laboral)}
        <Grid size={12} mx={0}>
          <LinearProgress sx={{ borderRadius: 25, visibility: loading ? "visible" : "hidden" }} />
        </Grid>
      </Grid>
    </>
  );
};
