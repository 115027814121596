import { MouseEvent, Suspense, useEffect, useState } from "react";
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { RootState } from "@store/store";

// import { createSelector } from "@reduxjs/toolkit";
import { DialogManager } from "@components/DialogManager";
import { PopupManager } from "@components/PopupManager";

import LogoLendia from "@assets/lendia_full.svg";
import AccountIcon from "@assets/account_icon.svg";
import { setOptionAppSelected } from "@store/slices/componentsSlice";
import { OptionApplicationMenu } from "@interfaces/slices";

export const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const userSession = useSelector((state: RootState) => state.app.session.user);
  const companiesUser = useSelector((state: RootState) => state.app.session.companies);

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdmin = () => {
    dispatch(setOptionAppSelected(OptionApplicationMenu.USUARIOS));
    navigate("/admin");
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    dispatch({ type: "CLEAN_STORE" });
    await signOut();
    navigate("/login");
  };

  useEffect(() => {
    if (["/login", "/cambiar_contrasenia", "/recuperar_cuenta"].some((item) => item === location.pathname) === false) {
      fetchAuthSession().then((value) => {
        if (value.tokens === undefined) {
          navigate("/login");
        }
      });
    }
  }, []);

  return (
    <>
      <Box height={"100vh"}>
        <AppBar position="static" sx={{ bgcolor: "white", boxShadow: 0 }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              {companiesUser?.length > 0 ? (
                <Box
                  component="img"
                  // src={`/src/assets/companies/${companiesUser.at(0)?.name.toLowerCase()}.svg`}
                  src={`${import.meta.env.VITE_URL_STATIC_RESOURCES}/finbe/bpm/${companiesUser
                    .at(0)
                    ?.name.toLowerCase()}.png`}
                  alt={companiesUser.at(0)?.name}
                />
              ) : (
                <Box component="img" src={LogoLendia} alt="logo" sx={{ height: 25 }} />
              )}
            </Box>
            <Box ml={4} mr={1}>
              <IconButton onClick={handleMenu}>
                <img src={AccountIcon} width={"22px"} height={"22px"} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
              >
                {userSession?.role?.code === "RADM2" && (
                  <MenuItem onClick={() => handleAdmin()}>Administración</MenuItem>
                )}
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </Menu>
            </Box>
            {companiesUser?.length > 0 && <Box component="img" src={LogoLendia} alt={"Lendia"} />}
          </Toolbar>
        </AppBar>
        <Box mx={2} height={"93vh"}>
          <Suspense fallback={<></>}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>

      <DialogManager />
      <PopupManager />
    </>
  );
};
