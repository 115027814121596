import { ResponseBase } from "./response";

export enum ModelStatus {
  PENDING = "PENDING",
  DOWNLOADING = "DOWNLOADING",
  DOWNLOADED = "DOWNLOADED",
  MISISNG = "MISISNG",
  DUPLICATE = "DUPLICATE",
  NO_HIT = "NO_HIT",
}

export interface ModelDownloadStatus {
  invoices: {
    status: ModelStatus;
  };
  payments: {
    status: ModelStatus;
  };
  items: {
    status: ModelStatus;
  };
  annual_tax_return: {
    status: ModelStatus;
  };
  tax_status: {
    status: ModelStatus;
  };
  tax_compliance: {
    status: ModelStatus;
  };
  client_bureau: {
    status: ModelStatus;
  };
  pf_bureau: {
    status: ModelStatus;
  };
  job: {
    imss: ModelStatus;
    issste: ModelStatus;
  };
}

export interface ModelDownloadStatusResponse {
  sucess: boolean;
  message: string;
  data: {
    invoices: {
      status: ModelStatus;
    };
    payments: {
      status: ModelStatus;
    };
    items: {
      status: ModelStatus;
    };
    annual_tax_return: {
      status: ModelStatus;
    };
    tax_status: {
      status: ModelStatus;
    };
    tax_compliance: {
      status: ModelStatus;
    };
    client_bureau: {
      status: ModelStatus;
    };
    pf_bureau: {
      status: ModelStatus;
    };
    job: {
      imss: ModelStatus;
      issste: ModelStatus;
    };
  };
  // loan_application_id: UUID;
  // company_profile_id: UUID;
  // status_invoice_id: UUID;
  // status_statement_id: UUID;
  // status_certificate_id: UUID;
  // status_compliance_opinion_id: UUID;
  // status_bureau_company_id: UUID;
  // status_bureau_person_id: UUID;
  // status_labor_information_id: UUID;
  // // comment_invoice: null;
  // // comment_statement: null;
  // // comment_certificate: null;
  // // comment_compliance_opinion: null;
  // // comment_bureau_company: null;
  // // comment_bureau_person: null;
  // // comment_labor_information: null;
  // active: boolean;
  // created_at: string;
  // updated_at: string;
  // id: UUID;
  // status_invoice: {
  //   id: UUID;
  //   name: string;
  //   description: string;
  //   code: string;
  //   created_at: string;
  //   updated_at: string;
  // };
  // status_statement: {
  //   id: UUID;
  //   name: string;
  //   description: string;
  //   code: string;
  //   created_at: string;
  //   updated_at: string;
  // };
  // status_certificate: {
  //   id: UUID;
  //   name: string;
  //   description: string;
  //   code: string;
  //   created_at: string;
  //   updated_at: string;
  // };
  // status_compliance_opinion: {
  //   id: UUID;
  //   name: string;
  //   description: string;
  //   code: string;
  //   created_at: string;
  //   updated_at: string;
  // };
  // status_bureau_company: {
  //   id: UUID;
  //   name: string;
  //   description: string;
  //   code: string;
  //   created_at: string;
  //   updated_at: string;
  // };
  // status_bureau_person: {
  //   id: UUID;
  //   name: string;
  //   description: string;
  //   code: string;
  //   created_at: string;
  //   updated_at: string;
  // };
  // status_labor_information: {
  //   id: UUID;
  //   name: string;
  //   description: string;
  //   code: string;
  //   created_at: string;
  //   updated_at: string;
  // };
}

export interface ModelDownloadStatusResponse extends ResponseBase {
  data: {
    page: number;
    size: number;
    total: number;
    total_pages: number;
    data: Array<ModelDownloadStatus>;
  };
}
