import { Box, Button, Stack, Typography } from "@mui/material";

import OkCheck from "@assets/OkCheck.svg";
import WarningCheck from "@assets/download_warning_icon.svg";
import InfoIcon from "@mui/icons-material/Info";

export interface NotificacionProps {
  title: string;
  iconType: "success" | "error" | "warning" | "info" | "none";
  onContinueClick: () => void;
}

export const EstatusNotificacion = (props: NotificacionProps) => {
  const handleOnContinueClicked = () => {
    props.onContinueClick();
  };
  return (
    <Box margin={4}>
      <Stack textAlign="center" spacing={2}>
        {props.iconType === "warning" ? <img src={WarningCheck} height={"65px"} /> : <></>}
        {props.iconType === "success" ? <img src={OkCheck} height={"65px"} /> : <></>}
        {props.iconType === "info" ? <InfoIcon sx={{ fontSize: 65, alignSelf: "center" }} color="primary" /> : <></>}

        {/* <img src={OkCheck} height={"65px"} /> */}
        <Typography variant="body1" fontWeight={600}>
          {props.title}
        </Typography>

        <Button variant="contained" color="primary" onClick={handleOnContinueClicked}>
          Aceptar
        </Button>
      </Stack>
    </Box>
  );
};
