import { useEffect, useState } from "react";

import { Grid2 as Grid, IconButton, Popper, styled } from "@mui/material";
import { PopupType } from "@interfaces/slices";
import { DetalleAbortivo } from "./PopupSegmentos/DetalleAbortivo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";

import CloseIcon from "@mui/icons-material/Close";
import { setPopupConfig } from "@store/slices/componentsSlice";

const Arrow = styled("span")({
  position: "absolute",
  width: "3em",
  height: "3em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: "1em",
    height: "1em",
    borderStyle: "solid",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: "0",
    border: "0px",
    borderTop: "1px solid #528cd6",
    borderLeft: "1px solid #528cd6",
    backgroundColor: "#FFF",
    borderTopLeftRadius: "6px",
  },
});

export const PopupManager = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const popupConfig = useSelector((state: RootState) => state.components.popup);

  const popupSection = new Map<PopupType, React.ReactNode>([
    [PopupType.NONE, <></>],
    [PopupType.DETALLE_ABORTIVO, <DetalleAbortivo props={popupConfig.props?.details} />],
  ]);

  const handleOnClosed = () => {
    dispatch(
      setPopupConfig({
        type: PopupType.NONE,
        props: undefined,
        anchorEl: undefined,
      }),
    );
  };

  useEffect(() => {
    if (popupConfig.anchorEl) {
      const tmp = document.querySelector(`#${popupConfig.anchorEl}_status`);
      setAnchorEl(tmp as HTMLElement);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [popupConfig.anchorEl]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      disablePortal={false}
      sx={{
        backgroundColor: "white",
        borderRadius: 4,
        mt: "15px !important",
        filter: "drop-shadow(5px 8px 10px rgba(0,0,0,0.32))",
        border: "1px solid #528CD6",
      }}
      modifiers={[
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: false,
            altBoundary: false,
            tether: true,
            rootBoundary: "document",
            padding: 20,
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
    >
      <Arrow ref={setArrowRef} />
      <Grid container sx={{ justifyContent: "right", position: "relative" }}>
        <IconButton sx={{ position: "absolute" }} onClick={handleOnClosed}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {popupSection.get(popupConfig.type)}
    </Popper>
  );
};
