import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid2 as Grid, Typography } from "@mui/material";

import { RootState } from "@store/store";
import { ButtonType, OptionApplicationMenu, PopupType } from "@interfaces/slices";
import { setOptionAppSelected, setPopupConfig, setVisibleButtons } from "@store/slices/componentsSlice";

export const Opciones = () => {
  const dispatch = useDispatch();

  const optionPrevSelected = useSelector((state: RootState) => state.components.menuApplication.optionSelected);

  const [optionSelected, setOptionSelected] = useState<OptionApplicationMenu>(optionPrevSelected);

  const handleOnChangeOption = (option: OptionApplicationMenu) => {
    dispatch(
      setPopupConfig({
        type: PopupType.NONE,
        props: undefined,
        anchorEl: undefined,
      }),
    );

    setOptionSelected(option);

    let btns = Array<{ btn: ButtonType; disabled: boolean }>();
    switch (option) {
      case OptionApplicationMenu.PERFIL_CREDITICIO:
      case OptionApplicationMenu.INFO_GRAL:
        btns.push(
          // { btn: ButtonType.VALIDAR_AUTORIZACION, disabled: false },
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      // case OptionApplicationMenu.PERFIL:
      //   btns.push(
      //     { btn: ButtonType.VALIDAR_AUTORIZACION, disabled: false },
      //     { btn: ButtonType.RECHAZAR, disabled: false },
      //     { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
      //   );
      //   break;
      case OptionApplicationMenu.VISITA_OCULAR:
        btns.push(
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      // case OptionApplicationMenu.DICTAMEN:
      case OptionApplicationMenu.DICTAMEN_LEGAL:
      case OptionApplicationMenu.CONTRACTUALES:
        btns.push(
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      case OptionApplicationMenu.EXPEDIENTE:
        btns.push(
          { btn: ButtonType.VALIDAR_EXPEDIENTE, disabled: false },
          { btn: ButtonType.RECHAZAR, disabled: false },
          { btn: ButtonType.RECHAZAR_BLOQUEAR, disabled: false },
        );
        break;
      case OptionApplicationMenu.RESUMEN_OPERACION:
        btns.push(
          { btn: ButtonType.LIBERAR_DISPERSION, disabled: false },
          { btn: ButtonType.CONVERTIR_CLIENTE, disabled: false },
        );
        break;
    }

    dispatch(setVisibleButtons(btns));
    dispatch(setOptionAppSelected(option));
  };

  useEffect(() => {
    handleOnChangeOption(optionPrevSelected);
  }, [optionPrevSelected]);

  return (
    <Grid container>
      <Grid size={10}>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.USUARIOS ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.USUARIOS)}
          >
            Usuarios
          </Typography>
        </Grid>
        <Grid borderBottom={"1px solid #A3D4E8"} borderRight={"1px solid #A3D4E8"} p={1}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.ROLES_PERMISOS ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.ROLES_PERMISOS)}
          >
            Roles y Permisos
          </Typography>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.MODULOS ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.MODULOS)}
          >
            Módulos
          </Typography>
        </Grid>

        <Grid borderRight={"1px solid #A3D4E8"} p={1} minHeight={"50px"}>
          <Typography
            p={1}
            borderRadius={1}
            sx={{
              backgroundColor: optionSelected === OptionApplicationMenu.CODIGOS_ATRIBUCION ? "#E3F2F8" : "",
              cursor: "pointer",
            }}
            variant="h5"
            onClick={() => handleOnChangeOption(OptionApplicationMenu.CODIGOS_ATRIBUCION)}
          >
            Códigos de atribución
          </Typography>
        </Grid>
      </Grid>
      <Grid size={"auto"}></Grid>
    </Grid>
  );
};
